/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-continue */
import { detect } from 'detect-browser';
import React, { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import { toast } from 'react-toastify';

import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa';
import { MdOutlineContentCopy } from 'react-icons/md';
import { PatternFormat } from 'react-number-format';
import { useHistory } from 'react-router-dom';
import {
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { captureEvent, captureException } from '@sentry/react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '../../components/modal';
import {
  calcTotalCart,
  createStoreEncodedName,
  debounce,
  findElementPosition,
  getCart,
  getDeliveryFee,
  getSelectedAddress,
  getSelectedStore,
  getStorageUser,
  getTakeAwayMode,
  getTrackId,
  getUser,
  isEmpty,
  isMobile,
  logout,
  removeSelectedStore,
  setStorageUser,
  setTempIdOrders,
  setTrackId,
  toBlr,
  usePromoCode,
  validateCpf,
} from '../../util';
import {
  ButtonPaymentOptions,
  CartCheckout,
  CartContainer,
  ColPayments,
  PixPaymentContent,
  RowDesktop,
} from './style';

import { useInternalContext } from '../../Context';
import { NewAddress } from '../../components/address';
import { Button } from '../../components/button';
import CartBody from '../../components/cart/body';
import CartFooter from '../../components/cart/footer';
import { ProductPrice } from '../../components/cart/style';
import CurrencyInput from '../../components/currency-input';
import Loading from '../../components/loading';
import { useCartContext } from '../../contexts/CartContext';
import { useCookieContext } from '../../contexts/CookieContext';
import {
  createCard,
  createNewAddress,
  fetchAddressInformation,
  fetchCards,
  fetchOpenHours,
  fetchStorePayments,
  fetchUserDetail,
  sendAttemptyToCard,
  sendChargeToCard,
  sendMetadata,
  sendOrder,
  verifyCardIsValid,
  verifyOrderPixStatus,
} from '../../service';
import { track } from '../../util/logUtil';
import { createOrder as parserOrder } from '../../util/order';
import { trackEvent } from '../../util/track';
import Icons from './Icons';
import CreditCardSection from './creditCardSection';

const browser = detect();

export const Checkout = () => {
  const { config, setUser, accountSelected, tableSelected } =
    useInternalContext();
  const { clearCart, removeFromCart, cart, removePromocode, removeCashback } =
    useCartContext();
  const { customerCookie } = useCookieContext();
  const history = useHistory();
  const [state, setState] = useState({
    customer: {
      cpf: '',
      customerId: 0,
      email: '',
      name: customerCookie.name || '',
      mainPhone: customerCookie.phone || '',
    },
    cards: [],
    loadingCep: false,
    loading: true,
    showQRCodeModal: false,
    qrCode: null,
    pixCopiaCola: '',
    newCard: false,
    change: 0,
    dates: [],
    height: 500,
    selectedPayment: {},
    isTakeAway: false,
    payTakeAway: false,
    isDelivery: true,
    loadingDeliveryInformation: false,
    addressInformation: {},
    paymentModal: false,
    loadingPayments: false,
    loadingOrder: false,
    deliveryIsCoverage: false,
    orderErrorMessage: '',
    creatingCardLoading: false,
    deliveryFee: null,
    validatingPromoCode: false,
    cardError: {},
    paymentsOptions: { payments: [] },
    payNow: false,
    payPix: false,
    deliveryStoreId: null,
    scheduledTime: null,
    openScheduleTimeModal: false,
    tempDayOfWeek: null,
    card: {},
    selectedStore: {},
    loadAddressFromCep: false,
    dayOfWeek: null,
    openHours1: [],
    openHours2: [],
    observation: '',
    hasLogin: null,
    cpfRequired: null,
    finishOrderButtonWasClickedDisabled: false,
    billingAddress: null,
    isLocalConsume: false,
    selectedLocalConsume: false,
  });
  const [validateCardModal, setValidateCardModal] = useState(false);
  const [validateCardModalStep, setValidateCardModalStep] = useState(1);
  const [validateCardExternalId, setValidateCardExternalId] = useState('');
  const [validateValueCharged, setValidateValueCharged] = useState(0);
  const [loadingModalValidateCard, setLoadingModalValidateCard] =
    useState(false);
  const [cpfCreditCard, setCpfCreditCard] = useState('');
  const [showBlockMessage, setShowBlockMessage] = useState(false);

  const totalToPay = calcTotalCart({
    cartProducts: [...cart.products],
    deliveryFee: getDeliveryFee(cart.deliveryFee),
    discount: cart.discount,
    cashback: cart.cashback,
    format: false,
  });

  const clearStatesValidationCard = () => {
    setShowBlockMessage(false);
    setValidateCardModal(false);
    setValidateCardModalStep(1);
    setValidateValueCharged(0);
  };

  const handleResize = () => {
    setState(prevValues => ({ ...prevValues, paymentModal: !!isMobile() }));
  };

  const fetchDeliveryData = useCallback(async () => {
    const { isDelivery } = state;

    if (isDelivery) {
      setState(prevValues => ({
        ...prevValues,
        deliveryStoreId: getSelectedStore().id || getSelectedStore().storeId,
        deliveryFee:
          cart.deliveryFee === undefined
            ? getSelectedStore().deliveryFee
            : cart.deliveryFee,
        deliveryIsCoverage: true,
        orderErrorMessage: null,
      }));
    }
  }, [cart.deliveryFee, state]);

  const loadData = useCallback(async () => {
    const user = getUser();
    const { hasLogin } = config;
    if (hasLogin === 'YES') {
      const customer = await fetchUserDetail({
        token: user?.token,
      });
      if (!isEmpty(customer)) {
        setState(prevValues => ({ ...prevValues, customer }));
      }
    }
    const selectedStore = getSelectedStore();

    if (!selectedStore) return;

    const [paymentsOptions, { cards }] = await Promise.all([
      fetchStorePayments(selectedStore.storeId || selectedStore.id),
      fetchCards(
        user?.token,
        getSelectedStore().id || getSelectedStore().storeId,
        hasLogin
      ),
    ]);

    setState(prevValues => ({
      ...prevValues,
      loading: false,
      selectedStore,
      paymentsOptions,
      cards,
      isTakeAway: selectedStore.type.toLowerCase() === 'takeaway',
      isDelivery: selectedStore.type.toLowerCase() === 'delivery',
      isLocalConsume: selectedStore.localConsume?.toLowerCase() === 'yes',
    }));

    if (selectedStore.type.toLowerCase() === 'takeaway') {
      setState(prevValues => ({
        ...prevValues,
        deliveryFee: 0,
      }));
    } else {
      fetchDeliveryData();
    }
  }, [config, fetchDeliveryData]);

  const scrollToPayment = () => {
    if (isMobile()) {
      const mobileBody = document.querySelector('.mobile-body');
      const modalMobileHeaderHeight = 60;
      mobileBody.scrollTo(
        0,
        findElementPosition(mobileBody.querySelector('.payment-methods')) -
          modalMobileHeaderHeight
      );
    } else {
      const headerHeight = 120;
      window.scroll(
        0,
        findElementPosition(document.querySelector('.payment-methods')) -
          headerHeight
      );
    }
  };

  const focusOnInput = className => {
    if (isMobile()) {
      document.querySelector('.mobile-body').scrollTo(0, 0);
      const mobileElement = document.querySelector(`.${className}-mobile`);
      if (mobileElement) {
        mobileElement.focus();
      }
    } else {
      window.scroll(0, 0);
      const element = document.querySelector(`.${className}`);
      if (element) {
        element.focus();
      }
    }
  };

  const createDates = useCallback(() => {
    const dates = [
      moment().add(parseInt(config.hoursAfterShceduled, 10), 'hours'),
    ];

    const vacationTimes = [
      '1702',
      '1802',
      '1902',
      '2002',
      '2102',
      '2202',
      '2302',
    ];

    if (
      vacationTimes.includes(moment().format('DDMM')) &&
      parseInt(config.merchantId, 10) === 31
    ) {
      dates[0] = moment('24/02/2021', 'DD/MM/YYYY');
    }
    const getVacationTime = daysToAdd => {
      const newDate = moment(dates[0]).add(daysToAdd, 'days');
      const formated = newDate.format('DDMM');
      const index = vacationTimes.findIndex(vacation => vacation === formated);
      if (index !== -1 && parseInt(config.merchantId, 10) === 31) {
        newDate.add(vacationTimes.length + 1 - daysToAdd + index, 'days');
      }

      return newDate;
    };
    const sizeMaxDates = [270].includes(parseInt(config.merchantId, 10))
      ? 30
      : 4;
    for (let i = 1; i <= sizeMaxDates; i += 1) {
      dates.push(getVacationTime(i));
    }

    if (parseInt(config.merchantId, 10) === 149) {
      const diffDate = moment('16/04/2022', 'DD/MM/YYYY').diff(
        dates[dates.length - 1],
        'days'
      );
      for (let i = 0; i <= diffDate; i += 1) {
        dates.push(getVacationTime(4 + i + 1));
      }
    }
    if (parseInt(config.merchantId, 10) === 117) {
      const diffDate = moment('28/04/2025', 'DD/MM/YYYY').diff(
        dates[dates.length - 1],
        'days'
      );
      for (let i = 0; i <= diffDate; i += 1) {
        dates.push(getVacationTime(4 + i + 1));
      }
    }
    if (parseInt(config.merchantId, 10) === 156) {
      const diffDate = moment('09/05/2021', 'DD/MM/YYYY').diff(
        dates[dates.length - 1],
        'days'
      );
      for (let i = 0; i <= diffDate; i += 1) {
        dates.push(getVacationTime(sizeMaxDates + i + 1));
      }
    }

    if (config.scheduledAllowToday === 'YES') {
      const todayIsInDates = dates.find(
        momentObject =>
          momentObject.format('DD/MM') === moment().format('DD/MM')
      );
      if (!todayIsInDates) dates.unshift(moment());
    }

    setState(prevValues => ({ ...prevValues, dates }));
  }, [
    config.hoursAfterShceduled,
    config.merchantId,
    config.scheduledAllowToday,
  ]);

  const payNow = () => {
    trackEvent({
      name: 'payWithCard',
      data: { user: getUser() ? getUser() : { name: 'Anônimo' } },
    });
    setState(prevValues => ({
      ...prevValues,
      payNow: true,
      payPix: false,
      payTakeAway: false,
      selectedPayment: { methodKey: 'oline', name: 'OnLine' },
    }));
    scrollToPayment();
  };

  const payWithPix = () => {
    trackEvent({
      name: 'payWithPix',
      data: { user: getUser() ? getUser() : { name: 'Anônimo' } },
    });

    setState(prevValues => ({
      ...prevValues,
      payNow: false,
      payPix: true,
      payTakeAway: false,
      selectedPayment: { methodKey: 'pix_online', name: 'Pix Online' },
    }));
    scrollToPayment();
  };

  const payTakeAway = () => {
    trackEvent({
      name: 'payWithOnDelivery',
      data: { user: getUser() ? getUser() : { name: 'Anônimo' } },
    });
    setState(prevValues => ({
      ...prevValues,
      payTakeAway: true,
      payPix: false,
      payNow: false,
    }));
    scrollToPayment();
  };

  const handleChangeCardInformation =
    cardField =>
    ({ target: { value } }) => {
      if (cardField === 'cvv' && `${value}`.length > 4) return;
      const { card, cardError } = state;
      card[cardField] = value;
      cardError[cardField] = false;
      setState(prevState => ({ ...prevState, card, cardError }));
    };

  const removeProduct = async product => {
    if (isMobile()) {
      removeFromCart(product.cartId);
    } else {
      confirmAlert({
        title: 'Remover produto',
        message: 'Deseja remover este produto do carrinho?',
        buttons: [
          {
            label: 'Sim',
            onClick: () => {
              removeFromCart(product.cartId);
            },
          },
          {
            label: 'Não',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const createNewCreditCard = async () => {
    const { billingAddress, card } = state;
    const user = getUser();
    setState(prevValues => ({ ...prevValues, creatingCardLoading: true }));

    const createdCard = await createCard(user?.token, {
      cardNumber: card.number.split(' ').join(''),
      cardExpirationDate: card.expiry.split(' ').join(''),
      holderName: card.name,
      billingAddress,
    });
    const { cards } = await fetchCards(
      user?.token,
      getSelectedStore().id || getSelectedStore().storeId
    );

    setState(prevValues => ({
      ...prevValues,
      cards,
      creatingCardLoading: false,
      newCard: false,
      cardError: {},
      card: {
        id: createdCard.id,
      },
    }));
  };

  const sucessOrder = async ({ order, result }) => {
    const { hasLogin } = config;
    const discountInfo = () => {
      if (!cart.promocodeId) {
        return {};
      }
      if (cart.zeroDelivery) {
        return { discount: 'delivery', discountValue: order.discount / 100 };
      }
      return { discount: 'cart', discountValue: order.discount / 100 };
    };
    trackEvent({
      name: 'Purchase',
      data: {
        currency: 'BRL',
        value: order.total / 100,
        deliveryFee: getDeliveryFee(cart.deliveryFee) / 100,
        payment: state.selectedPayment,
        storeId: order.storeId,
        storeName: getSelectedStore()?.name,
        ...discountInfo(),
        discountDescription: cart.discountDescription,
        promocodeId: cart.promocodeId,
        orderId: result.id,
        items: order.items.map((item, index) => ({
          item_id: item.product.id,
          item_name: item.product.name,
          quantity: item.quantity,
          index,
        })),
      },
    });

    await sendMetadata(result.id, {
      platform: browser.name,
      os: browser.os,
      platformVersion: browser.version,
      appVersion: process.env.VERSION,
      log: JSON.stringify(state),
    });
    clearCart();

    setTempIdOrders(result.id, hasLogin === 'YES');
    history.push(`/success/${result.id}`);
  };

  const checkPaymentStatus = ({
    selectedStore,
    result,
    hasLogin,
    user,
    order,
  }) => {
    verifyOrderPixStatus({
      storeId: state.deliveryStoreId || selectedStore.id,
      paymentId: result.paymentId,
      orderId: result.id,
      hasLogin,
      token: user?.token,
    })
      .then(statusResult => {
        if (statusResult.status === 'NOT_AUTHORIZED') {
          setTimeout(
            () =>
              checkPaymentStatus({
                selectedStore,
                result,
                hasLogin,
                user,
                order,
              }),
            5000
          );
          return;
        }
        if (['CONFIRMED', 'RECEIVED'].includes(statusResult.status)) {
          sucessOrder({ result, order });
        }
        if (
          [
            'OVERDUE',
            'REFUND_REQUESTED',
            'REFUNDED',
            'CHARGEBACK_REQUESTED',
            'CHARGEBACK_DISPUTE',
            'AWAITING_CHARGEBACK_REVERSAL',
            'DUNNING_REQUESTED',
            'DUNNING_RECEIVED',
          ].includes(statusResult.status)
        ) {
          loadData();
          toast.error('Erro ao finalizar pedido, tente novamente.');
        }
      })
      .catch(err => {
        setState(prevState => ({
          ...prevState,
          showQRCodeModal: false,
          loadingOrder: false,
          payNow: false,
          payPix: false,
          change: null,
          payTakeAway: false,
          selectedPayment: { id: null },
          paymentModal: false,
          paymentsOptions: {},
        }));
        captureException(err);

        loadData();
        toast.error(
          'Transação passou do tempo limite, por favor, tente novamente!'
        );
      });
  };

  const createOrder = order => {
    const { payPix, selectedStore, deliveryStoreId, customer } = state;
    const { hasLogin } = config;

    const user = getUser();

    if (hasLogin === 'NO') {
      setStorageUser({ ...customer });
      // setUser({
      //   ...customer,
      //   isTempUser: true,
      // });
    }

    if (payPix && (!order.customer.cpf || !order.customer.name)) {
      toast.warn('Para pagamentos PIX é obrigatório informar o nome e CPF');
      return;
    }

    sendOrder(
      deliveryStoreId || selectedStore.id,
      order,
      hasLogin === 'NO' ? null : user.token,
      hasLogin,
      getTrackId() || cart.salesTrackId
    ).then(result => {
      setTrackId(null);
      if (result.id) {
        if (result.isPix) {
          setState(prevState => ({
            ...prevState,
            showQRCodeModal: true,
            qrCode: result.pixQRCode,
            expirationDate: result.expirationDate,
            pixCopiaCola: result.payload,
          }));
          checkPaymentStatus({
            selectedStore,
            result,
            hasLogin,
            user,
            order,
          });

          return;
        }
        sucessOrder({ result, order });
      } else {
        trackEvent({
          name: 'completedOrderWithError',
          data: { user: user || { name: 'Anônimo' }, error: result.erro },
        });

        setState(prevState => ({
          ...prevState,
          loadingOrder: false,
          loading: false,
          payNow: false,
          payPix: false,
          change: null,
          payTakeAway: false,
          selectedPayment: { id: null },
          paymentsOptions: {},
        }));

        if (result.userMessage || result.erro) {
          loadData();
          toast.error(result.userMessage || result.erro);
          captureEvent({
            message: result.userMessage || result.erro,
            extra: { result },
          });
          if (result.valueOrderIsChanged) {
            history.push(``);
            removeSelectedStore();
            clearCart();
          }
        } else {
          loadData();
          captureEvent({
            message: 'Erro ao finalizar pedido, tente novamente.',
            extra: { result },
          });
          toast.error('Erro ao finalizar pedido, tente novamente.');
        }
      }
    });
  };

  const finishOrder = async () => {
    const sourceId = sessionStorage.getItem('sourceId') || 112;
    const externalId = sessionStorage.getItem('externalId');

    if (state.hasLogin === 'NO') {
      // Regex que valida os celulares válidos brasileiros
      const regexToPhoneValid =
        /^(1[1-9]|[4689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/;

      // Regex para uma sequencia de numeros repetidos
      const regexToRepeatedNumbers = /^([0-9])\1{10}/g;

      if (!state.customer.name) {
        focusOnInput('customerName');
        toast.warn('Nome obrigatório.');
        return;
      }

      if (!state.customer.mainPhone) {
        focusOnInput('customerMainPhone');
        toast.warn('Telefone obrigatório.');
        return;
      }
      console.log(
        '# telefone 1 : ',
        state.customer.mainPhone.match(regexToPhoneValid)
      );
      console.log(
        '# telefone 2 : ',
        state.customer.mainPhone.match(regexToRepeatedNumbers)
      );
      // if (
      //   !state.customer.mainPhone.match(regexToPhoneValid) ||
      //   state.customer.mainPhone.match(regexToRepeatedNumbers)
      // ) {
      //   focusOnInput('customerMainPhone');
      //   toast.warn('Número de Telefone Inválido!');
      //   return;
      // }

      if (state.cpfRequired === 'YES' && !validateCpf(state.customer.cpf)) {
        focusOnInput('customerCPF');
        toast.warn('o CPF não é válido');
        return;
      }
    }

    if (cart.products.length === 0) {
      toast.warn('Seu carrinho está vazio!');
      return;
    }

    setState(prevValues => ({ ...prevValues, loadingOrder: true }));
    const user = getUser();

    if (
      config.validationCreditCard === 'YES' &&
      state.payNow &&
      state.hasLogin === 'YES'
    ) {
      const data = await verifyCardIsValid({
        cpf: cpfCreditCard.replaceAll('-', '').replaceAll('.', ''),
        lastDigits: state.card.number.slice(-4),
        mainPhone: state.customer.mainPhone.replace(/\D/g, ''),
        token: user?.token,
        hasLogin: state.hasLogin,
      });

      if (data.error) {
        toast.error(data.error);
        captureEvent({
          message: data.error,
          extra: { data },
        });
        setState(prevState => ({ ...prevState, loadingOrder: false }));
        return;
      }

      if (data && !data.isValid) {
        setValidateCardModal(true);
        return;
      }
    }

    if (state.payNow && !state.card.id && state.hasLogin === 'YES') {
      const createdCard = await createCard(user?.token, {
        cardNumber: state.card.number.split(' ').join(''),
        cardExpirationDate: state.card.expiry.split(' ').join(''),
        holderName: state.card.name,
        billingAddress: state.billingAddress,
      });
      state.card.id = createdCard.id;
    }

    const selectedAddress = getSelectedAddress();
    if (selectedAddress) {
      delete selectedAddress.canEditStreet;
    }
    const store = getSelectedStore();
    const order = parserOrder({
      cartProducts: cart.products,
      discount: cart.discount,
      cashback: cart.cashback,
      sourceId,
      externalId,
      scheduled: config.hasScheduledOrder === 'YES',
      dayOfWeek: state.dayOfWeek,
      timeOfDay: state.selectUtcTime,
      discountId: -1,
      promocodeId: cart.promocodeId,
      address: selectedAddress,
      customer: {
        id: state.customer.id,
        name: state.customer.name,
        cpf: state.customer.cpf,
        email: state.customer.email,
        mainPhone: state.customer.mainPhone.replace(/\D/g, ''),
      },
      customerId: state.customer.id,
      deliveryFee: cart.zeroDelivery ? 0 : state.deliveryFee,
      deliveryCommission:
        state.selectedStore.type.toLowerCase() === 'takeaway'
          ? 0
          : store?.deliveryCommission || 0,
      storeId: state.deliveryStoreId || state.selectedStore.id,
      change: state.change * 100,
      isTakeAway: state.selectedStore.type.toLowerCase() === 'takeaway',
      payment: state.selectedPayment,
      observation: `${state.observation} ${
        state.selectedLocalConsume ? '***CONSUMIR NO LOCAL***' : ''
      }`,
      billingAddress: state.billingAddress,
      tableId: tableSelected?.id,
      accountId: accountSelected?.id,
    });

    if (state.payNow) {
      const merchantId = config.id;

      if (
        state.paymentsOptions.appPayment.gateway === 'PAGSEGURO' &&
        merchantId === 40
      ) {
        const cripted = window.PagSeguro.encryptCard({
          publicKey: state.paymentsOptions.appPayment.pubKey,
          holder: state.card.name,
          number: state.card.number.split(' ').join(''),
          expMonth: state.card.expiry.split(' ').join('').split('/')[0],
          expYear: `20${state.card.expiry.split(' ').join('').split('/')[1]}`,
          securityCode: state.card.cvv,
        });
        order.card = {
          cripted: cripted.encryptedCard,
        };
      } else {
        order.card = {
          ...state.card,
          id: state.card.id,
          cvv: state.card.cvv,
        };
      }
    }
    if (state.change && state.change * 100 < order.total) {
      toast.warn(
        'Valor pago em dinheiro precisa ser igual ou maior ao pedido.'
      );
      setState(prevState => ({ ...prevState, loadingOrder: false }));
      return;
    }
    if (state.isTakeAway) {
      setState(prevState => ({ ...prevState, paymentModal: false }));

      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        title: 'Buscar pedido na loja',
        message: `Você confirma que vai retirar o pedido na loja ${state.selectedStore.name} - ${state.selectedStore.address}?`,
        buttons: [
          {
            label: 'Não',
            onClick: () => {
              setState(prevState => ({
                ...prevState,
                loadingOrder: false,
                loading: false,
                change: null,
                payNow: false,
                payPix: false,
                payTakeAway: false,
                card: {},
                selectedPayment: { id: null },
                paymentModal: false,
              }));
            },
          },
          {
            label: 'Sim',
            onClick: async () => {
              if (isMobile()) {
                setState(prevState => ({ ...prevState, paymentModal: true }));
              }

              createOrder(order);
            },
          },
        ],
      });
    } else {
      createOrder(order);
    }
  };

  const finishOrderDisable = () => {
    if (!isMobile()) return;
    const modalMobileHeaderHeight = 80;
    const mobileBody = document.querySelector('.mobile-body');

    setState(prevState => ({
      ...prevState,
      finishOrderButtonWasClickedDisabled: true,
    }));

    if (
      config.hoursAfterShceduled !== null &&
      config.hoursAfterShceduled !== undefined &&
      !state.dayOfWeek
    ) {
      mobileBody.scrollTo(
        0,
        findElementPosition(mobileBody.querySelector('.scheduledRow')) - 50
      );
      return;
    }
    if (!state.payNow && !state.payTakeAway) {
      mobileBody.scrollTo(
        0,
        findElementPosition(mobileBody.querySelector('.paymentRow')) -
          modalMobileHeaderHeight
      );
    }
  };

  const createAddress = async e => {
    e.preventDefault();

    setState(prevValues => ({ ...prevValues, loadingCep: true }));
    const user = getUser();
    const address = {
      ...state.addressInformation,
      cep: state.addressInformation.zip,
    };
    delete address.zip;
    delete address.canEditStreet;
    await createNewAddress({
      body: {
        address,
      },
      token: user?.token,
    });
    setState(prevValues => ({
      ...prevValues,
      loadingCep: false,
      newAddressPopup: false,
      loading: true,
    }));

    const customer = await fetchUserDetail({
      token: user?.token,
    });
    if (!isEmpty(customer)) {
      setState(prevValues => ({
        ...prevValues,
        customer,
      }));
    }
    setState(prevValues => ({
      ...prevValues,
      loading: false,
      addressSelectModal: true,
    }));
  };

  const loadAddressFromCep = async zipCode => {
    setState(prevValues => ({
      ...prevValues,
      loadAddressFromCep: true,
    }));
    const addressInformation = await fetchAddressInformation(zipCode)
      .then(value => ({
        ...value,
        canEditStreet: !value.street || value.street.trim() === '',
        street: value?.street?.trim() || '',
        number: '',
        complement: '',
      }))
      .catch(err => {
        captureException(err);
        return {
          error: 'Cep não encontrado',
        };
      });
    setState(prevValues => ({
      ...prevValues,
      loadAddressFromCep: false,
      addressInformation,
    }));
  };

  const convertToLocalDate = ({ index, date }) => {
    const baseDate = moment()
      .startOf('week')
      .add(index ?? 1, 'day');

    const convertedDate = moment(
      `${baseDate.format('DD/MM/YYYY')} ${date} +0000`,
      'DD/MM/YYYY HH:mm Z'
    );

    return convertedDate;
  };

  const openHourSelection = date => {
    setState(prevValues => ({
      ...prevValues,
      openScheduleTimeModal: true,
      tempDayOfWeek: date,
    }));
  };

  const selectTime = () => {
    if (!state.selectUtcTime) {
      toast.warn('Você precisa selecionar um horário');
      return;
    }
    setState(prevValue => ({
      ...prevValue,
      dayOfWeek: prevValue.tempDayOfWeek.format('DD/MM'),
      openScheduleTimeModal: false,
    }));
    scrollToPayment();
  };

  const handleChange = ({ target: { name, value } }) => {
    setState(prev => ({
      ...prev,
      customer: {
        ...prev.customer,
        [name]: value,
      },
    }));
  };

  const handleChangeOnlyNumber = ({ target: { name, value } }) => {
    setState(prev => ({
      ...prev,
      customer: {
        ...prev.customer,
        [name]: value.replace(/\D/g, ''),
      },
    }));
  };

  const canFinishOrder = () => {
    if (state.payPix) {
      return true;
    }
    const hasLogin = state.hasLogin === 'YES';
    if (
      state.payNow &&
      hasLogin &&
      state.paymentsOptions?.appPayment.gateway.includes('ASAAS') &&
      state.card.token
    ) {
      return true;
    }
    if (
      state.payNow &&
      hasLogin &&
      state.paymentsOptions?.appPayment.gateway.includes('CIELO') &&
      state.card.id &&
      state.card.cvv
    ) {
      return true;
    }
    if (
      state.payNow &&
      !state.cardError.number &&
      !state.cardError.expiry &&
      hasLogin &&
      state.card.name &&
      state.card.name.trim() !== '' &&
      state.card.cvv &&
      state.billingAddress
    ) {
      return true;
    }
    if (
      state.payNow &&
      !state.cardError.number &&
      !state.cardError.expiry &&
      !hasLogin &&
      state.card.name &&
      state.customer.email &&
      state.customer.mainPhone &&
      state.customer.name &&
      state.customer.email.trim() !== '' &&
      state.customer.mainPhone.trim() !== '' &&
      state.customer.name.trim() !== '' &&
      state.card.name.trim() !== '' &&
      state.card.cvv &&
      state.billingAddress
    ) {
      return true;
    }
    if (!state.payNow && state.selectedPayment.id) {
      return true;
    }
    if (cart.cashback && cart.cashback === cart.total - cart.discount) {
      return true;
    }
    return false;
  };

  const init = useCallback(async () => {
    const store = getSelectedStore();
    const { hasLogin, cpfRequired } = config;
    const user = hasLogin === 'YES' ? getUser() : getStorageUser();
    setState(prevValues => ({ ...prevValues, hasLogin, cpfRequired }));
    if (user) {
      setState(prevValues => ({ ...prevValues, customer: user }));
    }
    if (user?.cpf) {
      setCpfCreditCard(user.cpf);
    }
    if (((hasLogin === 'YES' && !user) || !store) && !getTakeAwayMode()) {
      history.push('/login');
      return;
    }
    const { openHours1, openHours2 } = await fetchOpenHours({
      token: null,
      storeId: store.id || store.storeId,
      hasLogin,
      user: getUser(),
    });

    const openHours1Replaced = openHours1
      ? openHours1.map(open => open && open.replace('|', ' - '))
      : null;
    const openHours2Replaced = openHours2
      ? openHours2.map(open => open && open.replace('|', ' - '))
      : null;

    setState(prevValues => ({
      ...prevValues,
      openHours1: openHours1Replaced,
      openHours2: openHours2Replaced,
      paymentModal: !!isMobile(),
    }));

    track(getCart(), true, customerCookie?.phone);

    if (!getSelectedAddress() && getSelectedStore().type === 'delivery') {
      toast.info(
        'É preciso informar um endereço para finalizar um pedido para entrega'
      );
      history.push('/');
      return;
    }
    if (!getSelectedStore()) {
      history.push('/');
      return;
    }

    loadData(true);
    createDates();
  }, [config, createDates, customerCookie, history, loadData]);

  const verifyIfHourIsBetweenTwoMoment = ({
    hourToVerify,
    startHour,
    endHour,
  }) => {
    if (!startHour || !endHour) return false;

    const hourToVerifyNumber = Number(hourToVerify.split(':').join(''));
    const hour1Number = Number(startHour.split(':').join(''));
    const hour2Number = Number(endHour.split(':').join(''));

    return !!(
      hourToVerifyNumber >= hour1Number && hourToVerifyNumber <= hour2Number
    );
  };

  const getPossibleTimesWorkshift = ({ openHours, tempDayOfWeek, isToday }) => {
    if (!openHours || !openHours[tempDayOfWeek.weekday()]) {
      return { possibleTimes: null, startHour: null, endHour: null };
    }

    const start = convertToLocalDate({
      index: tempDayOfWeek.weekday(),
      date: openHours[tempDayOfWeek.weekday()].split('-')[0],
    });

    const end = convertToLocalDate({
      index: tempDayOfWeek.weekday(),
      date: openHours[tempDayOfWeek.weekday()].split('-')[1],
    });

    // Se o horário final for menor que o inicial, ajusta para o dia seguinte
    if (end.isBefore(start)) {
      end.add(1, 'day');
    }

    const startHour = start.format('HH:mm');
    const endHour = end.format('HH:mm');
    if (
      isToday &&
      parseInt(start.format('H'), 10) >=
        parseInt(moment().local().format('H'), 10)
    ) {
      const toAdd =
        parseInt(moment().local().format('H'), 10) -
        parseInt(start.format('H'), 10) +
        1;
      if (toAdd > 0) {
        start.add(toAdd, 'hours');
      }
    }
    const possibleTimes = [];
    const dateAvailableToSchedule = moment().add(
      config.hoursAfterShceduled || 1,
      'hours'
    );

    while (end.diff(start, 'minutes') >= 0) {
      const dateToSchedule = moment()
        .date(tempDayOfWeek.date())
        .year(tempDayOfWeek.year())
        .day(tempDayOfWeek.day())
        .month(tempDayOfWeek.month())
        .hour(start.hour())
        .minute(start.minute());

      if (dateToSchedule.isAfter(dateAvailableToSchedule)) {
        possibleTimes.push(moment(start));
      }

      // para aparecer opção 23:59 caso a loja aceite até esse horario
      if (start.format('HH:mm') === '23:45') {
        start.add('14', 'minutes');
        continue;
      }

      start.add('15', 'minutes');
    }

    return { possibleTimes, startHour, endHour };
  };

  const drawPossibleTimes = () => {
    const { tempDayOfWeek, openHours1, openHours2 } = state;
    if (!tempDayOfWeek || (!openHours1 && !openHours2)) {
      return null;
    }
    const isToday = tempDayOfWeek.format('DD/MM') === moment().format('DD/MM');

    const currentMomentMoreDeliveryForescast = moment().add(
      'minute',
      getSelectedStore().deliveryForecast
    );

    const {
      possibleTimes: possibleTimes1,
      startHour: startHour1,
      endHour: endHour1,
    } = getPossibleTimesWorkshift({
      openHours: openHours1,
      tempDayOfWeek,
      isToday,
    });
    const {
      possibleTimes: possibleTimes2,
      startHour: startHour2,
      endHour: endHour2,
    } = getPossibleTimesWorkshift({
      openHours: openHours2,
      tempDayOfWeek,
      isToday,
    });

    if (
      isToday &&
      config.scheduledAllowToday === 'YES' &&
      (verifyIfHourIsBetweenTwoMoment({
        hourToVerify: currentMomentMoreDeliveryForescast.format('HH:mm'),
        startHour: startHour1,
        endHour: endHour1,
      }) ||
        verifyIfHourIsBetweenTwoMoment({
          hourToVerify: currentMomentMoreDeliveryForescast.format('HH:mm'),
          startHour: startHour2,
          endHour: endHour2,
        }))
    ) {
      if (possibleTimes1) {
        possibleTimes1.unshift(currentMomentMoreDeliveryForescast);
      }
    }

    return possibleTimes2
      ? [...(possibleTimes1 || []), ...possibleTimes2]
      : possibleTimes1;
  };

  const copyPixToClipboard = async () => {
    const { pixCopiaCola } = state;
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const isInstagram = ua.indexOf('Instagram') > -1;

    // navigator clipboard precisa do https para funcionar
    // se não tiver https, criar um input para copiar o cod direto dele
    // por algum motivo o navigator.clipboard n funciona no mobile do instagram também
    if (!isInstagram && navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(pixCopiaCola).then(
        () => {
          toast.success('Pix Copia e Cola copiado com sucesso');
        },
        () => {
          toast.error('Não foi possível copiar o Pix Copia e Cola');
        }
      );
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = pixCopiaCola;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      new Promise((res, rej) => {
        // eslint-disable-next-line no-unused-expressions
        document.execCommand('copy') ? res() : rej();
      })
        .then(
          () => {
            toast.success('Pix Copia e Cola copiado com sucesso');
          },
          () => {
            toast.error('Não foi possível copiar o Pix Copia e Cola');
          }
        )
        .finally(() => textArea.remove());
    }
  };

  if (!config) {
    return <Loading message="Carregando informações..." />;
  }

  const translate = {
    0: 'Domingo',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sabado',
  };

  const showPaymentMethods =
    !cart.cashback ||
    cart.cashback === 0 ||
    cart.cashback !== cart.total - cart.discount;

  const payBody = (
    <div style={{ padding: 10 }}>
      {!state.loadingPayments && !state.loadingOrder && (
        <>
          {config.hasScheduledOrder === 'YES' && (
            <div className="scheduledRow">
              <div style={{ margin: '10px 0' }}>
                Qual o melhor dia para{' '}
                {state.isDelivery ? 'entregar' : 'retirar na loja'} suas
                compras?
              </div>
              <div
                style={{
                  display: isMobile() ? 'flex' : '',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: ' 100%',
                }}
              >
                {state.dates
                  .filter(
                    date =>
                      !state.openHours1 || state.openHours1[date.weekday()]
                  )
                  .map(date => (
                    <Button
                      key={date.unix()}
                      outline
                      onClick={() => openHourSelection(date)}
                      style={{
                        width: 250,
                        margin: 4,
                        color:
                          state.dayOfWeek === date.format('DD/MM')
                            ? config.bottomTextColor
                            : '',
                        backgroundColor:
                          state.dayOfWeek === date.format('DD/MM')
                            ? config.bottomBackgroundColor
                            : '',
                      }}
                      disabled={state.dayOfWeek === date.format('DD/MM')}
                    >
                      <div>
                        <div>{date.format('DD/MM')}</div>
                        <div>{translate[date.weekday()]}</div>
                        {state.selectUtcTime &&
                          state.dayOfWeek === date.format('DD/MM') && (
                            <div>
                              {`${state.selectUtcTime
                                .local()
                                .format('HH')}:${state.selectUtcTime
                                .local()
                                .format('mm')}`}
                            </div>
                          )}
                        {state.dayOfWeek !== date.format('DD/MM') && (
                          <div>
                            {state.openHours1 &&
                            state.openHours1[date.weekday()]
                              ? `${convertToLocalDate({
                                  index: date.weekday(),
                                  date: state.openHours1[date.weekday()].split(
                                    '-'
                                  )[0],
                                }).format('HH:mm -')}
                            ${convertToLocalDate({
                              index: date.weekday(),
                              date: state.openHours1[date.weekday()].split(
                                '-'
                              )[1],
                            }).format(' HH:mm')}`
                              : state.openHours2 &&
                                state.openHours2[date.weekday()]
                              ? '12:00 - 18:00'
                              : ''}
                            {state.openHours2 &&
                            state.openHours2[date.weekday()]
                              ? ` ${
                                  state.openHours1 ? 'e' : ''
                                }${convertToLocalDate({
                                  index: date.weekday(),
                                  date: state.openHours2[date.weekday()].split(
                                    '-'
                                  )[0],
                                }).format('HH:mm -')}
                          ${convertToLocalDate({
                            index: date.weekday(),
                            date: state.openHours2[date.weekday()].split(
                              '-'
                            )[1],
                          }).format(' HH:mm')}`
                              : ''}
                          </div>
                        )}
                      </div>
                    </Button>
                  ))}
              </div>
            </div>
          )}
          {showPaymentMethods && (
            <div
              style={{
                marginTop: 12,
                flexDirection: 'column',
                display:
                  state.dayOfWeek === null &&
                  config.hoursAfterShceduled !== undefined &&
                  config.hoursAfterShceduled !== null
                    ? 'none'
                    : 'flex',
              }}
              className="paymentRow"
            >
              <div>
                <span>Como você deseja pagar este pedido?</span>
              </div>
              <ColPayments xs={12}>
                {state.paymentsOptions.pixIntegration && totalToPay > 500 && (
                  <ButtonPaymentOptions
                    onClick={payWithPix}
                    outline
                    style={{
                      color: state.payPix ? config.bottomTextColor : '',
                      backgroundColor: state.payPix
                        ? config.bottomBackgroundColor
                        : '',
                    }}
                    config={config}
                  >
                    PIX (Online)
                  </ButtonPaymentOptions>
                )}
                {state.paymentsOptions?.appPayment &&
                  state.paymentsOptions.appPayment.gateway !== 'ASAAS_PIX' && (
                    <ButtonPaymentOptions
                      onClick={payNow}
                      outline
                      style={{
                        color: state.payNow ? config.bottomTextColor : '',
                        backgroundColor: state.payNow
                          ? config.bottomBackgroundColor
                          : '',
                      }}
                      config={config}
                    >
                      Pagar agora (Online)
                    </ButtonPaymentOptions>
                  )}
                {state.paymentsOptions?.payments?.filter(
                  ({ publishedApp, publishedAppTakeAway }) =>
                    state.isTakeAway ? publishedAppTakeAway : publishedApp
                ).length > 0 && (
                  <ButtonPaymentOptions
                    outline
                    config={config}
                    style={{
                      width: 150,
                      color: state.payTakeAway ? config.bottomTextColor : '',
                      backgroundColor: state.payTakeAway
                        ? config.bottomBackgroundColor
                        : '',
                    }}
                    onClick={payTakeAway}
                  >
                    Pagar na {state.isTakeAway ? 'retirada' : 'entrega'}
                  </ButtonPaymentOptions>
                )}
              </ColPayments>
            </div>
          )}
        </>
      )}
      <div className="payment-methods" style={{ marginTop: 28 }}>
        {state.payTakeAway && !state.loadingOrder && (
          <>
            <div>
              <div>
                <span>Selecione a forma de pagamento</span>
              </div>
              {state.selectedPayment.methodKey === 'dinheiro' && (
                <div>
                  <span>Troco para quanto?</span>
                  <CurrencyInput
                    name="change"
                    value={state.change}
                    onChange={(event, value) =>
                      setState(prevValues => ({ ...prevValues, change: value }))
                    }
                  />
                  <FormText>
                    Informe o total do pagamento em dinheiro, por exemplo R$
                    100,00
                  </FormText>
                </div>
              )}
            </div>
            <div>
              {state.paymentsOptions.payments
                .filter(({ publishedApp, publishedAppTakeAway }) => {
                  if (state.isTakeAway) {
                    return publishedAppTakeAway;
                  }
                  return publishedApp;
                })
                .map(payment => {
                  const disabled = state.selectedPayment.id === payment.id;
                  return (
                    <div
                      className="col-12 col-lg-6"
                      key={`payment_${payment.id}`}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Button
                        disabled={disabled}
                        outline
                        style={{
                          width: '90%',
                          marginTop: 4,
                          opacity: 1,
                          color: disabled ? config.bottomTextColor : '',
                          backgroundColor: disabled
                            ? config.bottomBackgroundColor
                            : '',
                        }}
                        onClick={() => {
                          setState(prevValues => ({
                            ...prevValues,
                            selectedPayment: payment,
                          }));
                          if (isMobile()) {
                            document
                              .querySelector('.mobile-body')
                              .scrollTo(
                                0,
                                document.querySelector('.mobile-body')
                                  .scrollHeight
                              );
                          } else {
                            window.scrollTo(0, document.body.scrollHeight);
                          }
                        }}
                      >
                        {payment.name}
                      </Button>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {state.payNow && !state.loadingOrder && state.cards.length > 0 && (
          <div>
            <div>Cartões cadastrados</div>
            <div>
              <hr />
            </div>
          </div>
        )}
        {state.payNow &&
          !state.loadingOrder &&
          state.cards.map(cardView => (
            <div key={`card_${cardView.cardLastDigits}`}>
              <div className="col-4 col-lg-6">
                <FormGroup check style={{ textAlign: 'left' }}>
                  <Label check>
                    <Input
                      type="radio"
                      checked={cardView.id === state.card.id}
                      style={{ width: 20, height: 20 }}
                      onChange={() => {}}
                      onClick={() => {
                        const sameCard = state.card.id === cardView.id;

                        setState(prevValues => ({
                          ...prevValues,
                          card: {
                            id: sameCard ? null : cardView.id,
                            cvv: sameCard ? null : cardView.cvv,
                            token: sameCard ? null : cardView.token,
                          },
                        }));
                      }}
                    />
                    <img
                      style={{ width: 30, marginLeft: 8 }}
                      src={Icons[cardView.brand]}
                      alt="Cartão de crédito"
                    />
                  </Label>
                </FormGroup>
              </div>

              <div className="col-6 col-lg-3" style={{ textAlign: 'left' }}>
                <div>{cardView.cardLastDigits}</div>
                <div>{cardView.holderName}</div>
              </div>
              {cardView.id === state.card.id &&
                state.paymentsOptions?.appPayment.gateway.indexOf('ASAAS') !==
                  -1 &&
                !cardView.token && (
                  <div className="col-12 col-lg-7">
                    <FormGroup>
                      <Label>Informe o código de segurança</Label>
                      <Input
                        style={{ width: 150 }}
                        type="number"
                        maxLength={4}
                        value={state.card.cvv}
                        onChange={handleChangeCardInformation('cvv')}
                      />
                    </FormGroup>
                  </div>
                )}

              <div>
                <hr />
              </div>
            </div>
          ))}
        {state.payNow && !state.loadingOrder && state.cards.length > 0 && (
          <div>
            <div>
              <Button
                onClick={() =>
                  setState(prevValues => ({ ...prevValues, newCard: true }))
                }
                outline
              >
                Cadastrar novo cartão
              </Button>
            </div>
          </div>
        )}
        {!state.loadingOrder && state.payNow && state.cards.length === 0 && (
          <CreditCardSection
            card={state.card}
            cpfCreditCard={cpfCreditCard}
            setCpfCreditCard={setCpfCreditCard}
            validationCreditCard={config.validationCreditCard === 'YES'}
            updateCardInfo={(name, value) =>
              setState(prevState => ({
                ...prevState,
                card: { ...prevState.card, [name]: value },
              }))
            }
            setNumberError={value =>
              setState(prevState => ({
                ...prevState,
                cardError: { ...prevState.cardError, number: value },
              }))
            }
            setCVVError={value =>
              setState(prevState => ({
                ...prevState,
                cardError: { ...prevState.cardError, cvv: value },
              }))
            }
            billingAddress={state.billingAddress}
            onSaveAddress={value =>
              setState(prevValue => ({
                ...prevValue,
                billingAddress: value,
              }))
            }
          />
        )}
      </div>
    </div>
  );

  const confirmSendChargeToCard = async () => {
    const store = getSelectedStore();
    const user = getUser();
    setLoadingModalValidateCard(true);

    const result = await sendChargeToCard({
      body: {
        customer: {
          id: state.customer.id,
          name: state.customer.name,
          cpf: cpfCreditCard.replaceAll('-', '').replaceAll('.', ''),
          email: state.customer.email,
          mainPhone: state.customer.mainPhone.replace(/\D/g, ''),
        },
        storeId: store.id || store.storeId,
        card: state.card,
        billingAddress: state.billingAddress,
        zip: state.addressInformation.zip,
      },
      token: user?.token,
      hasLogin: state.hasLogin,
    });

    setLoadingModalValidateCard(false);
    if (result.error) {
      toast.error(result.error);
      captureEvent({
        message: result.error,
        extra: { result },
      });
      return;
    }

    if (result.externalId) {
      setValidateCardExternalId(result.externalId);
    }

    setValidateCardModalStep(2);
  };

  const confirmSendAttempt = async () => {
    const user = getUser();
    setLoadingModalValidateCard(true);
    const result = await sendAttemptyToCard({
      token: user?.token,
      hasLogin: state.hasLogin,
      body: {
        valueChargedUser: parseInt(Math.round(validateValueCharged * 100), 10),
        externalId: validateCardExternalId,
        cpf: cpfCreditCard.replaceAll('-', '').replaceAll('.', ''),
        mainPhone: state.customer.mainPhone,
        lastDigits: state.card.number.slice(-4),
      },
    });

    setLoadingModalValidateCard(false);
    if (result.error) {
      toast.error(result.error);
      captureEvent({
        message: result.error,
        extra: { result },
      });
      if (result?.numberOfAttempts === 3) {
        setShowBlockMessage(true);
      }
      if (result?.numberOfAttempts === 4) {
        logout();
        clearCart();
        removeCashback(true);
        setUser(null);
        history.push('/');
      }
      return;
    }
    clearStatesValidationCard();
    finishOrder();
  };

  useEffect(() => {
    trackEvent({
      name: 'OpenCheckout',
    });
    if (config) {
      init();
    }

    window.addEventListener('resize', debounce(handleResize, 100));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 100));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.loading) {
    return <Loading message="Carregando informações..." />;
  }
  return (
    <>
      <Modal isOpen={state.showQRCodeModal} size="lg">
        <ModalHeader style={{ fontWeight: 'bold' }}>Pagamento PIX</ModalHeader>
        <ModalBody>
          <PixPaymentContent>
            <div className="qr-code">
              <h2>Pagar com QR Code</h2>
              <img
                alt="QR Code de pagamento do pedido"
                style={{ width: isMobile() ? '90%' : 250 }}
                id="base64image"
                src={`data:image/jpeg;base64, ${state.qrCode}`}
              />
            </div>
            <h3 style={{ fontSize: '1em', textAlign: 'center' }}>Ou</h3>
            <div className="copia-e-cola">
              <div className="input-wrapper-copia-e-cola">
                <button type="button" onClick={copyPixToClipboard}>
                  <span>Copiar código Pix Copia e Cola</span>
                  <MdOutlineContentCopy
                    style={{ transform: 'rotateY(180deg)', marginLeft: 5 }}
                    size={22}
                  />
                </button>
              </div>
            </div>
            <div>
              <ProductPrice style={{ fontWeight: 500 }}>Total: </ProductPrice>
              <ProductPrice style={{ fontWeight: 500 }}>
                {toBlr(totalToPay)}
              </ProductPrice>
            </div>
            <Loading message="Aguardando pagamento..." />
          </PixPaymentContent>
        </ModalBody>
        <ModalFooter>
          <Button
            outline
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                payPix: false,
                selectedPayment: { id: null },
                loadingOrder: false,
                showQRCodeModal: false,
              }));
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={state.openScheduleTimeModal} size="lg">
        <ModalHeader>Qual é o horário desejado?</ModalHeader>
        <ModalBody style={{ overflow: 'initial' }}>
          <Select
            options={drawPossibleTimes()?.map(time => ({
              value: time,
              label: time.format('HH:mm'),
            }))}
            onChange={option =>
              setState(prevValues => ({
                ...prevValues,
                selectUtcTime: option.value.utc(),
              }))
            }
            placeholder="Selecione um horário.."
            noOptionsMessage={() =>
              `Não existe mais horário disponível para o dia atual, por favor, selecione outro dia`
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            outline
            color="danger"
            onClick={() =>
              setState(prevValues => ({
                ...prevValues,
                openScheduleTimeModal: false,
                selectUtcTime: null,
                dayOfWeek: null,
              }))
            }
          >
            Cancelar
          </Button>
          <Button onClick={selectTime}>Selecionar horário</Button>
        </ModalFooter>
      </Modal>

      <NewAddress
        createAddress={createAddress}
        addressInformation={state.addressInformation}
        loadingCep={state.loadingCep}
        onChange={field => value => {
          setState(prevValues => ({ ...prevValues, [field]: value }));
          if (
            field === 'cep' &&
            value.replace('_', '').replace('-', '').length === 8
          ) {
            const cep = value.replace('_', '').replace('-', '');
            loadAddressFromCep(cep);
          }
          if (field !== 'cep') {
            setState(prevValue => ({
              ...prevValue,
              addressInformation: {
                ...prevValue.addressInformation,
                [field]: value,
              },
            }));
          }
        }}
        toggle={() =>
          setState(prevValues => ({ ...prevValues, newAddressPopup: false }))
        }
        isOpen={state.newAddressPopup}
      />

      <Modal isOpen={state.newCard} size="sm">
        <ModalBody>
          {state.creatingCardLoading && (
            <Loading message="Salvando novo cartão" />
          )}
          {!state.creatingCardLoading && (
            <CreditCardSection
              card={state.card}
              cpfCreditCard={cpfCreditCard}
              setCpfCreditCard={setCpfCreditCard}
              validationCreditCard={config.validationCreditCard === 'YES'}
              updateCardInfo={(name, value) =>
                setState(prevState => ({
                  ...prevState,
                  card: { ...prevState.card, [name]: value },
                }))
              }
              setNumberError={value =>
                setState(prevState => ({
                  ...prevState,
                  cardError: { ...prevState.cardError, number: value },
                }))
              }
              setCVVError={value =>
                setState(prevState => ({
                  ...prevState,
                  cardError: { ...prevState.cardError, cvv: value },
                }))
              }
              billingAddress={state.billingAddress}
              onSaveAddress={value =>
                setState(prevValue => ({
                  ...prevValue,
                  billingAddress: value,
                }))
              }
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            outline
            disabled={state.creatingCardLoading}
            color="danger"
            onClick={() =>
              setState(prevValues => ({
                ...prevValues,
                newCard: false,
                card: {},
                cardError: {},
              }))
            }
          >
            Cancelar
          </Button>
          <Button
            disabled={
              state.creatingCardLoading ||
              state.cardError.expiry ||
              state.cardError.number ||
              !state.card.name ||
              !state.card.name.trim() === '' ||
              !state.billingAddress
            }
            onClick={createNewCreditCard}
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={state.paymentModal}
        style={{ borderRadius: 0 }}
        size="lg"
        fade={false}
      >
        <ModalBody className="mobile-body" style={{ scrollBehavior: 'smooth' }}>
          {state.loadingOrder && <Loading message="Criando pedido.." />}
          {!state.loadingOrder && (
            <>
              <div style={{ padding: 10 }}>
                {state.loadingPayments && (
                  <Loading message="Carregando formas de pagamento" />
                )}
                {!state.loadingPayments && (
                  <header
                    style={{
                      position: 'sticky',
                      top: -20,
                      zIndex: 10,
                      backgroundColor: 'white',
                      paddingBottom: 8,
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <hr />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FaArrowLeft
                          onClick={() => history.push(`/`)}
                          color={config.textCategoryColor}
                        />
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <h1
                            style={{
                              color: config.textCategoryColor,
                              fontWeight: 'bold',
                              fontSize: '1.25rem',
                              marginBottom: 0,
                            }}
                          >
                            Finalize seu pedido
                          </h1>
                        </span>
                      </div>
                      <hr />
                    </div>
                  </header>
                )}
                {state.hasLogin === 'NO' && (
                  <div style={{ marginTop: 20 }}>
                    <div className="col-6">
                      <FormGroup>
                        <Label>Nome *</Label>
                        <Input
                          name="name"
                          type="text"
                          value={state.customer.name}
                          onChange={handleChange}
                          className="customerName-mobile"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-6">
                      <FormGroup>
                        <Label>Telefone *</Label>
                        <PatternFormat
                          format="(##) #####-####"
                          mask="_"
                          value={state.customer.mainPhone}
                          name="mainPhone"
                          onChange={handleChangeOnlyNumber}
                          className="customerMainPhone-mobile"
                          customInput={Input}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-6">
                      <FormGroup>
                        <Label>Email *</Label>
                        <Input
                          name="email"
                          type="text"
                          value={state.customer.email}
                          onChange={handleChange}
                          className="customerName-mobile"
                        />
                      </FormGroup>
                    </div>
                    {state.cpfRequired === 'YES' && (
                      <div>
                        <FormGroup>
                          <Label>CPF *</Label>
                          <PatternFormat
                            format="###.###.###-##"
                            mask="_"
                            value={state.customer.cpf}
                            name="cpf"
                            onChange={handleChangeOnlyNumber}
                            className="customerCPF-mobile"
                            customInput={Input}
                          />
                        </FormGroup>
                      </div>
                    )}
                  </div>
                )}
                {!getTakeAwayMode() && (
                  <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>
                    Endereço de {state.isTakeAway ? 'retirada' : 'entrega'}
                  </h4>
                )}
                {getTakeAwayMode() && (
                  <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>
                    Loja {getSelectedStore().name}
                  </h4>
                )}
                {!getTakeAwayMode() && (
                  <div style={{ marginBottom: '1rem' }}>
                    <div style={{ fontWeight: 400, fontSize: '0.75rem' }}>
                      {state.isTakeAway
                        ? getSelectedStore().address
                        : `${getSelectedAddress().street} ${
                            getSelectedAddress().number
                          } ${getSelectedAddress().complement}`}
                    </div>
                    {!state.isTakeAway && (
                      <div style={{ fontWeight: 400, fontSize: '0.75rem' }}>{`${
                        getSelectedAddress().city
                      }/${getSelectedAddress().state}`}</div>
                    )}
                  </div>
                )}
                {getSelectedStore().deliveryForecast &&
                  !(
                    config.hoursAfterShceduled !== null &&
                    config.hoursAfterShceduled !== undefined
                  ) && (
                    <>
                      <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>
                        Previsão de {state.isTakeAway ? 'retirada' : 'entrega'}
                      </h4>
                      <div style={{ marginBottom: 10 }}>
                        <div style={{ fontWeight: 400, fontSize: '0.75rem' }}>
                          {moment()
                            .local()
                            .add(
                              'minutes',
                              state.isTakeAway
                                ? getSelectedStore().takeAwayForecast
                                  ? getSelectedStore().takeAwayForecast
                                  : getSelectedStore().deliveryForecast / 2
                                : getSelectedStore().deliveryForecast
                            )
                            .format('DD/MM/YYYY HH:mm')}
                        </div>
                      </div>
                      {state.isTakeAway && state.isLocalConsume && (
                        <div style={{ marginLeft: 8, padding: '0 15px' }}>
                          <div style={{ fontWeight: 400 }}>
                            <Input
                              type="checkbox"
                              style={{
                                transform: 'scale(1.25)',
                              }}
                              onChange={() => {
                                setState(prevValues => ({
                                  ...prevValues,
                                  selectedLocalConsume:
                                    !state.selectedLocalConsume,
                                }));
                              }}
                            />
                            <span>Consumir no Local</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
              </div>
              {!state.loadingPayments && (
                <>
                  <div style={{ padding: 10 }}>
                    <FormGroup>
                      <Label>Observações do pedido</Label>
                      <Input
                        type="textarea"
                        value={state.observation}
                        maxLength="256"
                        onChange={({ target: { value } }) =>
                          setState(prevState => ({
                            ...prevState,
                            observation: value,
                          }))
                        }
                        name="observation"
                        style={{ resize: 'none' }}
                      />
                      {state.observation && (
                        <FormText>Máximo 256 caracteres</FormText>
                      )}
                    </FormGroup>
                  </div>
                  <CardHeader>
                    <h4 className="card-title text-black">
                      <small className="small">Seu pedido em</small> <br />
                      {getSelectedStore().name}
                    </h4>
                  </CardHeader>
                  <div
                    style={{
                      overflowY: 'overlay',
                      overflowX: 'hidden',
                      height: 250,
                      padding: '30px 15px',
                    }}
                  >
                    <CartBody
                      removeAction={removeProduct}
                      editAction={({ id }) =>
                        history.push(
                          `/loja/${createStoreEncodedName(
                            getSelectedStore().name
                          )}/${id}/edit`
                        )
                      }
                    />
                  </div>
                  <CartFooter
                    removePromocode={() => {
                      setState(prevValue => ({
                        ...prevValue,
                        deliveryFee: undefined,
                      }));
                      removePromocode();
                    }}
                    buttonGoToCheckout={false}
                  />
                </>
              )}
              {payBody}
            </>
          )}
        </ModalBody>
        {!state.loadingOrder && (
          <ModalFooter
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => {
                if (!canFinishOrder()) {
                  finishOrderDisable();
                } else {
                  finishOrder();
                }
              }}
              style={{
                color: config.bottomTextColor,
                backgroundColor: config.bottomBackgroundColor,
                opacity: !canFinishOrder() ? 0.65 : 1,
              }}
            >
              Finalizar pedido
            </Button>
            {state.payNow &&
              !canFinishOrder() &&
              state.finishOrderButtonWasClickedDisabled && (
                <FormText style={{ textAlign: 'center' }}>
                  <span style={{ color: 'red', fontSize: 12 }}>
                    Você precisa escolher ou preencher os dados do seu cartão de
                    crédito!
                  </span>
                </FormText>
              )}
            {!state.selectedPayment.id &&
              !state.payNow &&
              state.finishOrderButtonWasClickedDisabled && (
                <FormText style={{ textAlign: 'center' }}>
                  <span style={{ color: 'red', fontSize: 12 }}>
                    Você precisa selecionar ao menos uma forma de pagamento!
                  </span>
                </FormText>
              )}
          </ModalFooter>
        )}
      </Modal>

      <Modal isOpen={validateCardModal} style={{ maxWidth: 600 }}>
        <ModalHeader>Validar cartão de crédito</ModalHeader>
        {loadingModalValidateCard && <Loading />}
        {!loadingModalValidateCard && (
          <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
            {validateCardModalStep === 1 && (
              <>
                <p>
                  Ao apertar em confirmar será feita uma cobrança aleatória em
                  seu cartão de crédito. Você terá que informar o valor exato da
                  cobrança para seu cartão ser validado. (O valor será
                  estornado)
                </p>
                <div style={{ alignSelf: 'center' }}>
                  <Button onClick={confirmSendChargeToCard}>Confirmar</Button>
                </div>
              </>
            )}
            {validateCardModalStep === 2 && (
              <>
                <p>Digite o valor cobrado no seu cartão</p>
                <CurrencyInput
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                  }}
                  value={validateValueCharged}
                  onChange={(_, value) => setValidateValueCharged(value)}
                />
                {showBlockMessage && (
                  <small
                    style={{
                      display: 'block',
                      marginBottom: 5,
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    ATENÇÃO: Caso o valor informado esteja novamente incorreto.
                    Esta conta será bloqueada!
                  </small>
                )}
                <Button onClick={confirmSendAttempt}>Confirmar</Button>
              </>
            )}
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            outline
            color="danger"
            onClick={() => {
              clearStatesValidationCard();
              setState(prevValues => ({ ...prevValues, loadingOrder: false }));
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <CartCheckout id="checkout-container">
        {state.loadingOrder && <Loading message="Criando pedido.." />}
        {!state.loadingOrder && (
          <CardBody style={{ padding: 0, overflow: 'hidden' }}>
            {state.loadingDeliveryInformation && (
              <Loading message="Carregando informações de delivery" />
            )}
            {!state.loadingDeliveryInformation && (
              <div style={{ margin: 0, height: '100%', display: 'flex' }}>
                <Col
                  xs={12}
                  lg={7}
                  style={{ height: '100%' }}
                  className="order-1 order-lg-0"
                >
                  <h1
                    style={{
                      color: config.textCategoryColor,
                      fontWeight: 'bold',
                      marginBottom: 24,
                    }}
                  >
                    Finalize seu pedido
                  </h1>
                  {!getTakeAwayMode() && (
                    <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>
                      Endereço de {state.isTakeAway ? 'retirada' : 'entrega'}
                    </h4>
                  )}
                  {getTakeAwayMode() && (
                    <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>
                      Loja {getSelectedStore().name}
                    </h4>
                  )}
                  {!getTakeAwayMode() && (
                    <Row>
                      <Col xs={12} style={{ fontWeight: 400 }}>
                        {state.isTakeAway
                          ? getSelectedStore().address
                          : `${getSelectedAddress().street} ${
                              getSelectedAddress().number
                            } ${getSelectedAddress().complement}`}
                      </Col>
                      {!state.isTakeAway && (
                        <Col xs={12} style={{ fontWeight: 400 }}>{`${
                          getSelectedAddress().city
                        }/${getSelectedAddress().state}`}</Col>
                      )}
                    </Row>
                  )}
                  {getSelectedStore().deliveryForecast &&
                    !(
                      config.hoursAfterShceduled !== null &&
                      config.hoursAfterShceduled !== undefined
                    ) && (
                      <>
                        <hr />
                        <h4 style={{ fontWeight: 500, fontSize: 20 }}>
                          Previsão de{' '}
                          {state.isTakeAway ? 'retirada' : 'entrega'}
                        </h4>
                        <Row style={{ marginBottom: 10 }}>
                          <Col xs={12} style={{ fontWeight: 400 }}>
                            {moment()
                              .local()
                              .add(
                                'minutes',
                                state.isTakeAway
                                  ? getSelectedStore().takeAwayForecast
                                    ? getSelectedStore().takeAwayForecast
                                    : getSelectedStore().deliveryForecast / 2
                                  : getSelectedStore().deliveryForecast
                              )
                              .format('DD/MM/YYYY HH:mm')}
                          </Col>
                        </Row>
                        {state.isTakeAway && state.isLocalConsume && (
                          <Row style={{ marginLeft: 8 }}>
                            <Col xs={12} style={{ fontWeight: 400 }}>
                              <Input
                                type="checkbox"
                                style={{
                                  transform: 'scale(1.25)',
                                  marginTop: '0.4rem',
                                }}
                                onChange={() => {
                                  setState(prevValues => ({
                                    ...prevValues,
                                    selectedLocalConsume:
                                      !state.selectedLocalConsume,
                                  }));
                                }}
                              />
                              <span>Consumir no Local</span>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  {state.hasLogin === 'NO' && (
                    <Row style={{ marginTop: 20 }}>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>Nome * </Label>
                          <Input
                            name="name"
                            type="text"
                            value={state.customer.name}
                            onChange={handleChange}
                            className="customerName"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>Telefone *</Label>
                          <PatternFormat
                            format="(##) #####-####"
                            mask="_"
                            value={state.customer.mainPhone}
                            name="mainPhone"
                            onChange={handleChangeOnlyNumber}
                            className="customerMainPhone"
                            customInput={Input}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            name="email"
                            type="text"
                            value={state.customer.email}
                            onChange={handleChange}
                            className="customerName"
                          />
                        </FormGroup>
                      </Col>
                      {state.cpfRequired === 'YES' && (
                        <Col xs={6}>
                          <FormGroup>
                            <Label>CPF *</Label>
                            <PatternFormat
                              format="###.###.###-##"
                              mask="_"
                              value={state.customer.cpf}
                              name="cpf"
                              onChange={handleChangeOnlyNumber}
                              className="customerCPF"
                              customInput={Input}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  )}
                  <RowDesktop style={{ padding: 15 }}>
                    {payBody}
                    <div>
                      <FormGroup style={{ marginTop: 20 }}>
                        <Label>Observações do pedido</Label>
                        <Input
                          type="textarea"
                          value={state.observation}
                          maxLength="256"
                          onChange={({ target: { value } }) =>
                            setState(prevValue => ({
                              ...prevValue,
                              observation: value,
                            }))
                          }
                          name="observation"
                          style={{ resize: 'none' }}
                        />
                        {state.observation && (
                          <FormText>Máximo 256 caracteres</FormText>
                        )}
                      </FormGroup>
                      <Button
                        style={{
                          marginTop: 80,
                          width: '100%',
                          color: config.bottomTextColor,
                          backgroundColor: config.bottomBackgroundColor,
                        }}
                        onClick={finishOrder}
                        disabled={!canFinishOrder() || state.loadingOrder}
                      >
                        Finalizar pedido
                      </Button>
                    </div>
                  </RowDesktop>
                </Col>
                <Col
                  xs={12}
                  lg={5}
                  style={{
                    height: '100%',
                    paddingBottom: 15,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  className="order-0 order-lg-1"
                >
                  <CartContainer>
                    <CardHeader>
                      <h4 className="card-title text-black">
                        <small className="small">Seu pedido em</small> <br />
                        {getSelectedStore().name}
                      </h4>
                    </CardHeader>
                    <div
                      style={{
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        padding: 30,
                      }}
                    >
                      <CartBody
                        removeAction={removeProduct}
                        editAction={({ id }) =>
                          history.push(
                            `/loja/${createStoreEncodedName(
                              getSelectedStore().name
                            )}/${id}/edit`
                          )
                        }
                      />
                    </div>
                    <CartFooter
                      deliveryFee={getDeliveryFee(cart.deliveryFee)}
                      applyPromoCode={usePromoCode}
                      colorPromoCode={config.textCategoryColor}
                      removePromocode={() => {
                        setState(prevValue => ({
                          ...prevValue,
                          deliveryFee: undefined,
                        }));
                        removePromocode();
                      }}
                    />
                  </CartContainer>
                </Col>
              </div>
            )}
          </CardBody>
        )}
      </CartCheckout>
    </>
  );
};
